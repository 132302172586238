<!--
  PACKAGE_NAME : src\pages\esp\user\management\config.vue
  FILE_NAME : config
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 등록/수정 페이지
-->
<template>
  <div class="container">
    <div class="page-sub-box clearfix ui-glid-box top-box">
      <div class="fl per50">
        <table class="table_form line-bin">
          <caption>
            <strong>계정관리</strong>
          </caption>
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt">
            <tr>
              <td colspan="2">
                <h2>계정 정보</h2>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label5">
                  <span>아이디</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <DxTextBox
                  placeholder="사용자ID"
                  v-model="formData.loginId"
                  :disabled="config.updateYn"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxAsyncRule :validation-callback="checkValidLoginId" message="이미 사용하고 있는 ID 입니다." />
                    <DxRequiredRule message="사용자ID는 필수입니다." />
                    <DxCustomRule :validation-callback="checkEngAndNumber" message="영문, 숫자로만 구성되어야 합니다. 다시 입력하세요." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>이름</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <DxTextBox
                  v-model="formData.loginNm"
                  placeholder="사용자명"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="사용자명은 필수입니다." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT' && config.updateYn && formData.changedPwdFl">
              <th scope="row">
                <label for="label5">
                  <span>비밀번호 변경<br />유형</span>
                  <span class="icon_require">필수항목</span>
                  <button
                    class="btn-question"
                    id="tooltips1"
                    @mouseenter="setTooltips('changePwdType')"
                    @mouseleave="setTooltips('changePwdType')"
                  ></button>
                  <DxPopover target="#tooltips1" :visible="config.isTooltip.changePwdType" :close-on-outside-click="false">
                    <span
                      >‘새 비밀번호’는 사용자가 수정된 비밀번호를 계속 사용하도록 허용합니다.<br />
                      ‘1회용 임시 비밀번호’는 사용자의 로그인 직후 비밀번호 변경이 반드시 필요합니다.
                    </span>
                  </DxPopover>
                </label>
              </th>
              <td class="clearfix">
                <DxSelectBox
                  v-model="formData.tempPwdFl"
                  placeholder="비밀번호 변경 유형"
                  :items="config.tempPwdFls"
                  display-expr="title"
                  value-expr="value"
                  @value-changed="onChangedTempPwdFl"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="비밀번호 변경 유형은 필수값입니다." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>비밀번호</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <DxTextBox
                  v-model="formData.loginPwd"
                  placeholder="비밀번호"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="50"
                  styling-mode="outlined"
                  :width="config.updateYn ? '60%' : '80%'"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="비밀번호는 필수값입니다." />
                    <template>
                      <DxCustomRule :validation-callback="checkPrePwd" message="이전 패스워드를 다시 사용할 수 없습니다." />
                    </template>
                  </DxValidator>
                </DxTextBox>
                <DxButton
                  v-if="config.updateYn"
                  :text="config.changePwdTxt"
                  style="margin-left: 10px; vertical-align: middle"
                  :width="100"
                  :height="30"
                  class="default filled txt_S medium"
                  :use-submit-behavior="false"
                  @click="onClickPasswordChange"
                />
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>비밀번호 확인</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <DxTextBox
                  v-model="formData.loginPwdConfirm"
                  placeholder="비밀번호 확인"
                  mode="password"
                  :show-clear-button="true"
                  :disabled="formData.isDisabledPwd"
                  :max-length="50"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="비밀번호 확인값은 필수값입니다." />
                    <DxCompareRule :comparison-target="passwordComparison" message="비밀번호값과 일치하지 않습니다." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>계정 권한</span>
                  <span class="icon_require">필수항목</span>
                  <template>
                    <button
                      class="btn-question"
                      id="tooltips2"
                      @mouseenter="setTooltips('selectAuth')"
                      @mouseleave="setTooltips('selectAuth')"
                    ></button>

                    <DxPopover target="#tooltips2" :visible="config.isTooltip.selectAuth" :close-on-outside-click="false">
                      <span>`권한을 선택하여 메뉴와 검색 대상 노출을 자동 선택할 수 있습니다.`</span>
                    </DxPopover>
                  </template>
                </label>
              </th>
              <td class="clearfix">
                <DxDropDownBox
                  ref="authIdDropDown"
                  v-model="formData.authId"
                  :data-source="config.authList"
                  display-expr="authNm"
                  value-expr="id"
                  placeholder="계정 권한 선택"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                  :input-attr="{ 'aria-label': 'Owner' }"
                  :defer-rendering="false"
                  :show-clear-button="false"
                  :opened="config.isTreeBoxOpened"
                  @opened="onOpened"
                >
                  <template #content="{}">
                    <DxTreeView
                      :data-source="config.authList"
                      data-structure="plain"
                      key-expr="id"
                      display-expr="authNm"
                      parent-id-expr="parentId"
                      :expand-all-enabled="true"
                      :select-by-click="true"
                      selection-mode="single"
                      @item-selection-changed="onChangedAuth"
                    />
                  </template>
                  <DxValidator validation-group="validationGroupName">
                    <DxCompareRule :comparison-target="compareAuth" message="계정권한은 필수값입니다." />
                  </DxValidator>
                </DxDropDownBox>
              </td>
            </tr>

            <tr v-if="config.updateYn">
              <th scope="row">
                <label for="label5">
                  <span>계정 상태</span>
                </label>
              </th>
              <td>
                {{ formData.memberStateNm }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
            :text="config.insertBtnNm"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            validation-group="validationGroupName"
            :use-submit-behavior="true"
            @click="onSaveFormData"
          />
          <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onCancelFormData" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { DxAsyncRule, DxCompareRule, DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopover } from 'devextreme-vue/popover';
  import DxTreeView from 'devextreme-vue/tree-view';

  import { getRegexPattern, isSuccess, encryptPassword } from '@/plugins/common-lib';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';

  export default {
    components: {
      DxDropDownBox,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxAsyncRule,
      DxCustomRule,
      DxTextBox,
      DxSelectBox,
      DxButton,
      DxPopover,
      DxTreeView,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          stylingMode: 'outlined', // [outlined, filled, underlined]
          isTreeBoxOpened: false, // 계정권한 selectbox tree 오픈 여부
          updateYn: false, // 등록/수정 여부 체크
          insertBtnNm: '등 록',
          changePwdTxt: '비밀번호 변경',
          authList: [], // 메뉴권한 selectbox data list
          isTooltip: {
            changePwdType: false, // 비밀번호 변경 유형 여부
            selectAuth: false, // 권한선택 여부
          },
          tempPwdFls: [
            //비밀번호 변경 유형 여부
            {
              title: '새 비밀번호',
              value: 0,
            },
            {
              title: '1회용 임시 비밀번호',
              value: 2,
            },
          ],
        },
        formData: {
          id: null, // Member Entity Id
          loginId: '', // 사용자ID
          loginNm: '', // 사용자명
          loginPwd: '', // 비밀번호 필드
          loginPwdConfirm: '', // 비밀번호 확인 필드
          prePwd: '', // 비밀번호 변경 전 비밀번호
          authId: -1, // NumberFormatException 때문에 -1로 선언
          tempPwdFl: null, // 임시 비밀번호 여부
          changedPwdFl: true, // 비밀번호 변경 여부
          isDisabledPwd: true, // 비밀번호 입력창 비활성화 여부
          memberStateCd: null, // 계정 상태 코드
          memberStateNm: null, // 계정 상태 명
        },

        limitNumberTexts: {
          maxLengths: {
            loginNm: 50,
            loginId: 50,
            loginPwd: 50,
            loginPwdConfirm: 50,
            userId: 20,
            userNm: 10,
            ext: 7,
            mobile: {
              number1: 3,
              number2: 4,
              number3: 4,
            },
          },
        },
      };
    },
    computed: {
      getAuthType() {
        return this.$_getSystemData('auth_type')?.configValue || 'DEFAULT';
      },
      /** @description : 시스템 설정의 default_pwd 가져오기 */
      getSystemDefaultPwd() {
        return this.$_getSystemData('default_pwd').configValue;
      },
    },
    methods: {
      /** @description : 비밀번호 변경 버튼 클릭 이벤트 */
      onClickPasswordChange() {
        if (!this.formData.changedPwdFl) {
          //비밀번호 변경
          this.config.changePwdTxt = '취 소';
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
          this.formData.originTempPwdFl = this.formData.tempPwdFl;
          this.formData.tempPwdFl = null;
        } else {
          //비밀번호 변경 취소
          this.config.changePwdTxt = '비밀번호 변경';
          this.formData.changedPwdFl = false;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = '    ';
          this.formData.loginPwdConfirm = '    ';
          this.formData.tempPwdFl = this.formData.originTempPwdFl;
        }
      },
      /** @description: 비밀번호 변경 유형 이벤트 */
      onChangedTempPwdFl(e) {
        if (e.event && e.value === 2) {
          //1회용 임시 비밀번호
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = this.getSystemDefaultPwd;
          this.formData.loginPwdConfirm = this.getSystemDefaultPwd;
        } else {
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        }
      },
      /** @description: 비밀번호 변경 유형 or 권한선택 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description : 아이디 체크 true/false 리턴 이벤트 */
      checkValidLoginId(e) {
        return this.checkLoginId(e.value);
      },
      /** @description : 아이디 체크 API 호출 메서드  */
      async checkLoginId(loginId) {
        let data = { loginId: loginId };
        let payload = {
          actionname: 'MEMBER_LIST_ALL',
          data: { params: data },
          loading: true,
        };

        let res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data.length === 0;
        } else {
          return false;
        }
      },
      /** @description: 영문,숫자인지 체크하는 메서드 */
      checkEngAndNumber(e) {
        return !!getRegexPattern('checkEngAndNumber').test(e.value);
      },
      /** @description : 비밀번호 일치하는지 체크하는 이벤트 */
      passwordComparison() {
        return this.formData.loginPwd;
      },
      /** @description: 권한 선택 여부 validate */
      compareAuth() {
        return this.formData.authId < 0 ? 'false' : this.formData.authId;
      },
      /** @description : 비밀번호 체크 메서드 */
      checkPassword(loginPwd, loginId, loginNm) {
        const checkValueOrErrMsg = this.$_validPwd(loginPwd, loginId, loginNm);
        if (checkValueOrErrMsg === false) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.PWD_INVALID', { defaultValue: '유효하지 않는 비밀번호 입니다.' }));
          return false;
        } else if (typeof checkValueOrErrMsg === 'string') {
          this.$_Msg(checkValueOrErrMsg);
          return false;
        }

        const hangulRegexPattern = getRegexPattern('checkHangul');
        if (this.formData.loginPwd.search(/\s/) !== -1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.PWD_NON_EMPTY', { defaultValue: '비밀번호는 공백 없이 입력해주세요.' }));
          return false;
        } else if (hangulRegexPattern.test(this.formData.loginPwd)) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.PWD_NON_KR', { defaultValue: '비밀번호에 한글을 사용 할 수 없습니다.' }));
          return false;
        }

        return true;
      },
      /** @description: 변경될 패스워드와 이전 패스워드 체크 */
      checkPrePwd() {
        const changedPwd = encryptPassword(this.formData.loginId, this.formData.loginPwd, this.$store.getters.getEncryptionType);
        return changedPwd !== this.formData.prePwd;
      },
      /** @description : 권한 selectbox 변경 이벤트
       */
      onChangedAuth(e) {
        if (e.itemData) {
          this.formData.authId = e.itemData.id;
          this.config.isTreeBoxOpened = false;
        }
      },
      /** @description : 메뉴권한 selectbox 오픈 이벤트 */
      onOpened() {
        this.config.isTreeBoxOpened = true;
      },
      /** @description : 수정 페이지시 해당 계정 데이터 가져오기 */
      async selectFormData(res) {
        if (isSuccess(res)) {
          let data = res.data.data[0];
          this.formData.loginNm = data.loginNm;
          this.formData.loginId = data.loginId;
          this.formData.id = data.id;
          this.formData.authId = data.authId !== null ? Number(data.authId) : null;
          this.formData.tempPwdFl = Number(data.tempPwdFl);
          this.formData.prePwd = data.prePwd;
          this.formData.memberStateCd = data.memberStateCd;
          this.$_enums.common.memberState.values.filter(item => {
            if (item.value === data.memberStateCd) {
              this.formData.memberStateNm = item.label;
            }
          });
        }
      },
      /** @description : 저장/수정 버튼 클릭시 데이터 저장 */
      async onSaveFormData(e) {
        // 입력폼 validation 체크
        if (!e.validationGroup.validate().isValid) return;

        // 등록시
        if (!this.config.updateYn) {
          const checkLoginId = await this.checkLoginId(this.formData.loginId);
          if (!checkLoginId) {
            return false;
          }

          //비밀번호 validation 정규식 체크
          const validationCheck = this.checkPassword(this.formData.loginPwd, this.formData.loginId, this.formData.loginNm);

          if (!validationCheck) {
            return false;
          }

          //회원가입시 임시패스워드 상태로 설정
          this.formData.tempPwdFl = 1;
        } else {
          //수정시
          if (this.formData.changedPwdFl) {
            //비밀번호 변경시

            //비밀번호 validation 정규식 체크
            const validationCheck = this.checkPassword(this.formData.loginPwd, this.formData.loginId, this.formData.loginNm);

            if (!validationCheck) {
              return false;
            }
          }
        }

        if (await this.$_Confirm('해당 정보를 저장하시겠습니까?')) {
          let formData = this.formData;

          //비밀번호 hash 설정
          if (formData.changedPwdFl) {
            formData.loginPwd = encryptPassword(formData.loginId, formData.loginPwd, this.$store.getters.getEncryptionType);
          }

          const payload = {
            actionname: 'MEMBER_LIST_INSERT',
            data: [
              {
                id: formData.id,
                authId: formData.authId,
                loginId: formData.loginId,
                loginNm: formData.loginNm,
                loginPwd: formData.loginPwd,
                memberState: formData.memberStateCd == null?
                  this.$_enums.common.memberState.TEMPORARY.value : formData.memberStateCd,
              },
            ],
            loading: false,
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            await this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
            await this.$_setStoreSiteTenant(); //사이트,센터 store 재설정
            await this.$router.push('/esp/user/management/list');
          }
        } else {
          return false;
        }
      },
      /** @description : 취소 버튼 클릭시 리스트 페이지로 이동 */
      onCancelFormData() {
        this.$router.push('/esp/user/management/list');
      },
      /** @description : 초기 데이터 promise all로 가져오기 */
      async callPromiseAllInitData() {
        let payload;
        let returnDatas;
        let defaultDataApiList = [];

        //권한 관련 데이터 조회 payload
        payload = this.callApiPayloadAuthData();
        defaultDataApiList.push(await this.CALL_API(payload));

        returnDatas = await Promise.all(defaultDataApiList);

        this.selectAuthList(returnDatas[0]);

        //수정 페이지일 경우
        let apiList = [];
        if (this.config.updateYn) {
          //해당 계정 데이터 조회 payload
          const payload = {
            actionname: 'MEMBER_LIST_ALL',
            data: { params: { memberId: this.formData.id } },
            loading: true,
          };

          apiList.push(await this.CALL_API(payload));
        }

        returnDatas = await Promise.all(apiList);

        if (this.config.updateYn) {
          await this.selectFormData(returnDatas[0]);
        }
      },
      /** @description: 권한 관련 데이터 조회 payload */
      callApiPayloadAuthData() {
        return {
          actionname: 'AUTH_LIST_SUB',
          data: { authId: this.$store.getters.getAuthId },
          loading: true,
        };
      },
      /** @description: 권한 정보 가져오기 */
      async selectAuthList(res) {
        if (isSuccess(res)) {
          // parentId가 없는 데이터를 최상위로 처리
          const topLevelAuths = res.data.data.filter(item => item.parentId === null);
          if (topLevelAuths.length === 0) {
            // 최상위 데이터가 없을 경우, parentId가 있는 데이터들을 최상위로 변경
            res.data.data.forEach(item => {
              if (item.parentId !== null) {
                item.parentId = null; // parentId를 null로 변경하여 최상위로 이동
              }
            });
          }

          this.config.authList = res.data.data;
        }
      },
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        this.reqParams = this.$store.getters.getDetailParams;
        if (!this.reqParams) {
          this.$_goPrePage();
          return;
        }

        this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
        if (this.config.updateYn) {
          this.formData.id = this.reqParams.id;
          this.formData.authId = this.reqParams.authId;
          this.formData.loginId = this.reqParams.loginId;
        }
        this.formData.changedPwdFl = !this.config.updateYn;
        this.formData.isDisabledPwd = this.config.updateYn;

        //초기 데이터 호출
        await this.callPromiseAllInitData();

        if (this.config.updateYn) {
          //수정
          this.config.insertBtnNm = '저 장';
        }
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        if (typeof String.prototype.parseFunction != 'function') {
          String.prototype.parseFunction = function () {
            const funcReg = /function *\(([^()]*)\)[ \n\t]*{(.*)}/gim;
            const match = funcReg.exec(this.replace(/\n/g, ' '));

            if (match) {
              return new Function(match[1].split(','), match[2]);
            }

            return null;
          };
        }
      },
      /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$store.commit('setDetailParams', null);
      },
    },
    async created() {
      await this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>

<style scoped>
  .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: 0;
  }

  .top-box {
    height: auto;
  }

  .top-box > div {
    height: auto;
  }

  .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }

  .table_form td .empty-box {
    width: 10px;
  }
</style>
